<template>
  <div class="content" v-if="liveList.length > 0">
    <div class="title">
      <span>大会日程</span>
      <span class="float-right title-more" @click="clickMore">更多<van-icon name="arrow" /></span>
    </div>
    <div>
      <div ref="mySwiper" class="swiper-container banner-wrapper live-banner">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in liveList" :key="index" :data-index="index" class="swiper-slide banner-item">
            <!-- <img :src="require('@/assets/images/logo-water.png')" alt="" class="logo"> -->
            <!-- <div class="line">
              <div class="box"></div>
            </div> -->
            <div class="item-title">
              <!-- <span class="line"></span> -->
              <template v-if="(item.name.indexOf('助力新材料创新发展') !== -1) ">
                <!-- {{ item.name.split(' ') }} -->
                <span v-for="item in item.name.split(' ')">{{item}}</span>
              </template>
              <span v-else>{{item.name}}</span>
            </div>
            <div class="item-data">
              <!-- <van-icon class="item-icon" name="underway-o" />{{item.startDate.substr(5, 10)}} -->
              <van-icon class="item-icon" name="underway-o" />{{item.startDate.substr(5, 10) + ' ' + item.startTime.substr(0, 5)}}开始
            </div>
            <div class="item-place">
              <van-icon class="item-icon" name="location-o" />
              <span>{{item.address}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="item" v-for="(item, index) in liveList" :key="index">
        <div class="date" :style="{ background: $store.state.themeInfo.themeColor, opacity: 0.5 }">{{ item.startDate }}</div>
        <div class="wrap">
          <div class="left">
            <p style="font-size:12px; color:#8e8e8e;">{{item.startTime.substr(0, 5)}}-{{item.endTime.substr(0, 5)}} &nbsp; {{ item.address }}</p>
            <p style="font-size:15px;">{{item.name}}</p>
          </div>
          <div class="right" :style="{ background: $store.state.themeInfo.themeColor }" @click="changeRouter(item)">查看详情</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.css'
import { Dialog, Toast } from 'vant'
export default {
  name: 'liveContent',
  props: {
    miceLink: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      liveIcon: require('@/assets/images/icon/live.png'),
      liveList: [],
      loading: false,
      swiper: {}
    }
  },
  mounted() {
    this.getLiveList()
    window.addEventListener('resize', this.windowResize)
  },
  methods: {
    windowResize() {
      // this.swiper.destroy(true, true)
      if (this.$route.path.indexOf('index') !== -1) {
        const timer = setTimeout(() => {
          this.initSwiper()
        }, 500)
        if (this.swiper.destroy) {
          this.swiper.destroy(true, true)
          this.swiper = {}
        } else {
          clearTimeout(timer)
        }
      }
    },
    async getLiveList() {
      this.loading = true
      let result = await this.$http.get(this.$baseURL.index.getGroupList() + '?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      this.loading = false
      if (result.code === 200) {
        result.data.forEach((item) => {
          if (item.display === 1) {
            this.liveList.push(item)
          }
        })
        // this.liveList.splice(1, 0, {
        //   name: '岳麓之夜—湖湘群英荟',
        //   startDate: '2022-04-15',
        //   startTime: '20:00:00',
        //   endTime: '23:00:00',
        //   address: '谢子龙影像艺术馆1楼多功能厅',
        //   groupLink: 'pushed'
        // })
        setTimeout(() => {
          this.initSwiper()
        }, 200)
      } else {
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
    },
    changeRouter(item) {
      console.log(item);
      // if(item.name === '开幕式|岳麓论坛') {
      //   Toast('敬请期待')
      //   return
      // }
      if (!item.groupLink) {
        return
      }
      // if (item.linkUrl === 'show-test-none') {
      //   Toast({
      //     message: '敬请期待',
      //     position: 'middle'
      //   })
      //   this.$nextTick(() => {
      //     document.querySelector('.van-toast').style.background = 'rgb(255,255,255)'
      //     document.querySelector('.van-toast').style.color = '#333'
      //     document.querySelector('.van-toast').style.boxShadow = '0 0 5px 1px rgba(0,0,0,.05)'
      //   })
      //   return
      // }

      this.$store.commit('groupInfo/SET_GROUP', item)
      this.$router.push('/conference/' + this.$store.state.miceInfo.miceLink + '/' + item.groupLink)
      // if (item.linkType === 1) {
      //   this.$router.push('/selfpage/' + item.linkUrl + '/' + this.$store.state.miceInfo.miceLink)
      // } else {
      //   if (item.linkUrl === 'show-test') {
      //     Toast({
      //       message: '敬请期待',
      //       position: 'middle'
      //     })
      //     this.$nextTick(() => {
      //       document.querySelector('.van-toast').style.background = 'rgb(255,255,255)'
      //       document.querySelector('.van-toast').style.color = '#333'
      //       document.querySelector('.van-toast').style.boxShadow = '0 0 5px 1px rgba(0,0,0,.05)'
      //     })
      //     return
      //   }
      //   window.location.href = item.linkUrl
      // }
    },
    initSwiper() {
      const vm = this
      this.swiper = new Swiper ('.live-banner', {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: -15,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false
        // },
        loop: true,
        speed: 600,
        observer: true,
        on: {
          click: function () {
            vm.changeRouter(vm.liveList[this.clickedSlide.dataset.index])
            // Toast('敬请期待')
          }
        }
      })        
    },
    clickMore() {
      this.$parent.$parent.onChange(1)
    }
  }
}
</script>
<style scoped lang="css"></style>
<style lang="less" scoped>
.content{
  position: relative;
  width: 100%;
  background: transparent;
  z-index: 1;
  padding: 14px 0 30px;
  .title{
    // display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding: 15px;
    .title-more{
      font-size: 14px;
      font-weight: normal;
      color: #fff;
      display: flex;
      align-items: center;
    }
    img{
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }
  }
  .item{
    width: 100%;
    box-shadow: 0 0 10px 3px rgba(0,0,0,.1);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    .date{
      height:35px;
      // border-top-left-radius: 5px;
      // border-top-right-radius: 5px;
      line-height: 35px;
      font-size: 15px;
      padding: 0 5px;
      background: #8bcbf8;
      color: #fff;
    }
    .wrap{
      display: flex;
      width: 100%;
      padding: 10px;
      // justify-content: center;
      align-items: center;
      .left{
        width: 75%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          // padding: 5px;
          width: 100%;
          white-space: nowrap;
        }
      }
      .right{
        font-size: 12px;
        width: 25%;
        color:#fff;
        display: inline-block;
        text-align: center;
        padding: 8px 15px;
        border-radius: 15px;
        background: #29a2f8;
      }
    }
  }
}
.v-enter{
  transform: translateX(-200px);
  opacity: 0;
}
.v-enter-active{
  transition: all .5s ease;
}

.banner-wrapper {
  width: 100%;
  // margin: 14px 0 30px;
}
.banner-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 300px;
  height: 118px;
  // line-height: 1;
  background: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408261153031614565.jpg') 0 0/cover no-repeat;
  // background: linear-gradient(to bottom, rgb(39, 100, 186) 0%, rgb(60, 23, 146) 100%);
  box-shadow: 0px 2px 3px 0px rgba(18, 0, 50, 0.14);
  border-radius: 8px;
  margin: 0;
  padding: 8px;
  color: #fff;
  overflow: hidden;
  overflow: hidden;
  // align-items: center;
  .logo{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    width: 60px;
  }
  .line{
    position: absolute;
    left: 10px;
    height: 80px;
    width: 6px;
    // border-top-right-radius: 50%;
    // border-bottom-right-radius: 50%;
    .box{
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before{
        content: '';
        position: absolute;
        height: 240px;
        width: 240px;
        background-color: #fff;
        border-radius: 50%;
        right: 0;
      }
    }
  }
  .item-title {
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 25px;
    // width: 50%;
    // float: right;
    font-size: 14.5px;
    // font-weight: 600;
    span {
      // width: 135px;
      text-align: right;
    }
    // .line {
    //   margin-left: 2px;
    //   // display: inline-block;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   // padding: 1px;
    //   width: 13PX;
    //   height: 13PX;
    //   border-radius: 50%;
    //   // background: #fff;
    //   border: 1PX solid #fff;
    //   box-shadow: 0px 2px 3px 0px rgba(18, 0, 50, 0.14);
    //   &::before{
    //     content: '';
    //     height: 7PX;
    //     width: 7PX;
    //     border-radius: 50%;
    //     background: #fff;
    //   }
    // }
    span:nth-of-type(2) {
      // margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      
    }
  }
  .item-data {
    // margin-left: 25px;
    // margin-bottom: 8px;
    margin: 9px 0 8px 25px;
    justify-content: flex-end;
    font-size: 9px;
    // display: flex;
    align-items: center;
    line-height: 1;
  }
  .item-place {
    justify-content: flex-end;
    margin-left: 25px;
    // flex: 1;
    // display: flex;
    align-items: center;
    font-size: 9px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .item-icon {
    margin-right: 8px;
  }
}

 .swiper-slide {
    transition: 300ms;
    transform: scale(0.85);
    opacity: 0.85;
  }
	.swiper-slide-active,.swiper-slide-duplicate-active{
    transform: scale(1);
    opacity: 1;
	}
</style>