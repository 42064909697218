<template>
  <div class="news-wrapper">
    <!-- <van-skeleton class="mt-20" title :row="3" :loading="loading"/>
    <div class="page-container" v-if="newsOne.title !== undefined && !loading">
      <div class="title">
        <van-icon class="img" :style="{ color: $store.state.themeInfo.themeColor }" name="newspaper-o" />头条
      </div>
      <div style="display:flex; flex-direction:column">
        <transition appear>
          <span class="link" @click="changeRouter(newsOne)">
            <img :src="newsOne.iconUrl" alt="">
            <p>{{ newsOne.title }}</p>
          </span>
        </transition>
        <transition-group appear tag="div" style="display:flex; flex-direction:column">
          <span class="sec-link" v-for="(item, index) in newsList" :key="item.id" @click="changeRouter(item)" :style="{transitionDelay: 0.2*(index+1) + 's'}">
            <div class="sec-link-box">
              <div class="text-box" >
                <span class="title">{{ item.title }}</span>
                <p>{{ item.subtitle }}</p>
              </div>
              <img :src="item.iconUrl" alt="">
            </div>
            <van-divider style="margin:0"></van-divider>
          </span>
        </transition-group>
      </div>
    </div> -->
    <div class="news-head">
      <span>动态</span>
      <span @click="newsMore">更多<van-icon name="arrow" /></span>
    </div>
    <!-- <div class="news-banner">
      <van-swipe :autoplay="3000" :loop="true" :width="315" style="height: 100%;">
        <van-swipe-item v-for="(item) in newsList" :key="item.id" class="banner-item" @click="changeRouter(item)">
          <div class="banner-content">
            <img :src="item.iconUrl" alt="">
            <div class="bottom-text">
              <van-notice-bar :text="item.title" />
            </div>
          </div> 
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="banner-wrapper">
      <div class="swiper-container news-banner">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in newsList"
            :key="item.id"
            :data-index="index"
            class="swiper-slide banner-item"
          >
            <div class="banner-content">
              <img :src="item.iconUrl" alt="" />
              <div class="bottom-text">
                <van-notice-bar :text="item.title" />
              </div>
            </div>
          </div>
        </div>
        <div class="news-swiper-pagination"></div>
      </div>
    </div>

    <div class="news-list">
      <transition-group appear tag="div" style="display: flex; flex-direction: column">
        <div
          v-for="(item, index) in newsList"
          :key="item.id"
          class="news-item"
          v-show="index < 2"
          :style="{ transitionDelay: 0.2 * (index + 1) + 's' }"
          @click="changeRouter(item)"
        >
          <div class="news-left">
            <span class="news-title">
              <span>{{ item.title }}</span>
            </span>
            <div class="news-bottom">
              <!-- <span class="news-time">{{ item.createTime.substr(0, 10) }}</span> -->
              <span class="news-time">{{ getRealTime(item) }}</span>
              <!-- <span class="click-num">
                <img :src="require('@/assets/images/ylfh/icon_eye.png')" alt="">
                <span>{{item.clickNum}}</span>
              </span> -->
            </div>
          </div>
          <div class="news-right">
            <img :src="item.iconUrl" alt="" />
          </div>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.css";
import { Dialog } from "vant";
export default {
  name: "newsContent",
  data() {
    return {
      newsIcon: require("@/assets/images/icon/news.png"),
      newsOne: {},
      newsList: [],
      loading: true,
    };
  },
  activated() {
    this.getNewsList();
  },
  mounted() {
    // this.getNewsList()
    // this.initUserSwiper();
  },
  methods: {
    // 用户列表swiper
    initUserSwiper() {
   
    },
    getRealTime(item) {
      if (item.subtitle.indexOf("20") !== -1 && item.subtitle.indexOf("-") !== -1) {
        return item.subtitle;
      } else {
        return item.createTime.substr(0, 10);
      }
    },
    async getNewsList() {
      // 获取新闻列表
      // let result = await this.$http.get(this.$baseURL.index.getNewsList() + '?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      const result = await this.$http.post(this.$baseURL.index.getNewsPageList(), {
        miceLink: this.$store.state.miceInfo.miceLink,
        // isDisplay: 1,
      });
      console.log(result);
      if (result.total && result.records.length > 0) {
        this.newsList = [];
        this.loading = false;
        if (result.records.length > 0) {
          result.records.forEach((item, index) => {
            // if (item.display === 1 && index < 8) {
            if (index < 8) {
              this.newsList.push(item);
            }
          });
          this.newsOne = this.newsList[0] || {};
          this.$nextTick(() => {
            this.initSwiper();
          });
        }
      } else {
        this.loading = false;
        Dialog.alert({
          title: "提示",
          message: result.message,
        }).then(() => {
          // on close
        });
      }
    },
    changeRouter(item) {
      if (item.linkType === 0) {
        window.location.href = item.linkUrl;
      } else {
        this.$router.push(
          "/selfpage/" + item.linkUrl + "/" + this.$store.state.miceInfo.miceLink
        );
      }
      this.addClickNum(item.id);
    },
    initSwiper() {
      const vm = this;
      this.swiper = new Swiper(".news-banner", {
        spaceBetween: 10,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".news-swiper-pagination",
        },
        loop: true,
        speed: 600,
        on: {
          click: function () {
            vm.changeRouter(vm.newsList[this.clickedSlide.dataset.index]);
          },
        },
      });
    },
    newsMore() {
      this.$router.push("/news/" + this.$store.state.miceInfo.miceLink);
    },
    // 新增点击量
    addClickNum(id) {
      this.$http
        .post(
          this.$baseURL.index.clickNum(),
          {
            id,
            tableType: 2,
          },
          1
        )
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-skeleton__row,
.van-skeleton__title {
  background: #dedede;
}

.user-wrapper {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  .user-item {
    min-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
    }
    .username {
      font-size: 12px;
      color: #fff;
      margin-top: 5px;
    }
  }
}
.v-enter {
  transform: translateX(200px);
  opacity: 0;
}
.v-enter-active {
  transition: all 0.5s ease;
}
</style>
<style lang="less" scoped>
.news-wrapper {
  position: relative;
  padding: 0 15px 20px;
  z-index: 1;
  .news-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px;
    span:nth-of-type(1) {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
    span:nth-of-type(2) {
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      img {
        width: 7px;
        margin-left: 4px;
      }
    }
  }
  .banner-wrapper {
    position: relative;
    width: 345px;
    height: 170px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0px 1px 2px 0px rgba(183, 180, 188, 0.14);
    padding: 10px 10px 0;
    border-radius: 8px;
    .news-banner {
      width: 100%;
      height: 100%;
    }
    .banner-item {
      position: relative;
      width: 100%;
      height: 140px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
      .banner-content {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .bottom-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        border-radius: 0 0 5px 5px;
        background: rgba(0, 0, 0, 0.5);
        /deep/ .van-notice-bar {
          width: 100%;
          height: 100%;
          background: none;
          font-size: 10px;
          color: #fff;
        }
      }
    }
    .news-swiper-pagination {
      position: absolute;
      width: 100%;
      bottom: 0px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/.swiper-pagination-bullet {
        height: 6px;
        width: 6px;
        transition: 0.3s;
        background: rgba(0, 0, 0, 0.23);
      }
      /deep/.swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 3px;
        background: var(--themeColor);
      }
    }
  }
  .news-list {
    margin-top: 15px;
    .news-item {
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      width: 345px;
      height: 110px;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      box-shadow: 0px 1px 2px 0px rgba(183, 180, 188, 0.14);
      border-radius: 10px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
      .news-left {
        padding: 0px 16px 5px 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .news-title {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 21px;
          color: #333333;
          span {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .news-bottom {
          display: flex;
          justify-content: space-between;
          .news-time {
            font-size: 12px;
            color: #666666;
          }
          .click-num {
            font-size: 11px;
            color: #696969;
            img {
              width: 13px;
              margin-right: 3px;
            }
          }
        }
      }
      .news-right {
        width: 120px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
    }
  }
}


</style>
