<template>
  <div class="content" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <!-- <div class="title"><van-icon class="img" :style="{ color: $store.state.themeInfo.themeColor }" name="medal-o" />大咖来了</div>
    <div>
      <van-swipe
        :loop="true"
        :autoplay="4000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="(item, index) in famousList"
          :key="index"
          style="display: flex"
        >
          <div class="live-link live-b" v-for="(item, index) in item" :key="index">
            <a @click.prevent="changeRouter(item)">
              <img :src="item.iconUrl" alt="">
              <van-icon class="play-icon" name="play-circle-o" />
            </a>
            <p class="text-ellipsis">{{ item.name }}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="video-title">
      <span>新区风采</span>
      <span class="float-right title-more" @click="moreVideo">更多<van-icon name="arrow" /></span>
    </div>
    <div class="swiper-main">
      <div class="video-swiper-container video-wraper">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in famousList" :key="index" :data-index="index" class="swiper-slide daka-item">
            <div class="img-box">
              <img :src="item.iconUrl+'?imageView2/2/w/375'" alt="">
              <van-icon class="play-icon" name="play-circle" />
            </div>
            <!-- <div class="daka-title">item.title</div> -->
            <div class="daka-desc">《{{ item.name }}》</div>
          </div>
        </div>
        <div class="video-swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.css'
export default {
  name: 'dakaContent',
  data() {
    return {
      icon: require('@/assets/images/icon/person.png'),
      famousList: [],
      loading: false,
      swiper: null
    }
  },
  mounted() {
    this.getFamousList()
  },
  methods: {
    async getFamousList() {
      this.loading = true
      let result = await this.$http.get(this.$baseURL.index.getVideoList() + '?miceLink=' + this.$store.state.miceInfo.miceLink, 1)
      this.loading = false
      if (result.code === 200) {
        // let newArr = [], list = []
        // if(result.data.length % 2 === 1 && result.data.length > 1){
        //   newArr = result.data.concat(result.data);
        // }else{
        //   newArr = result.data;
        // }
        // newArr.forEach((item) => {
        //   if(item.display === 1) {
        //     list.push(item)
        //   }
        // })
        // for(var i = 0; i < list.length; i+=2){
        //   this.famousList.push(list.slice(i, i+2))
        // }
        this.famousList = result.data
        this.$store.commit('videoInfo/SET_VIDEO', this.famousList)
        this.$nextTick(() => {
          this.initSwiper()
        })
      } else {
        Dialog.alert({
          title: '提示',
          message: result.message,
        }).then(() => {
          // on close
        })
      }
    },
    changeRouter(item) {
      if (item.linkType === 1) {
        this.$router.push('/selfpage/' + item.linkUrl + '/' + this.$store.state.miceInfo.miceLink)
      } else {
        this.$store.commit('videoInfo/SET_CURRENT', item)
        this.$router.push('/micevideo/' + this.$store.state.miceInfo.miceLink + '/' +item.id)
      }
      this.addClickNum(item.id)
    },
    moreVideo() {
      this.$store.commit('videoInfo/SET_CURRENT', this.famousList[0])
      this.$router.push('/micevideo/' + this.$store.state.miceInfo.miceLink + '/' + this.famousList[0].id)
    },
    initSwiper() {
      const vm = this
      this.swiper = new Swiper('.video-swiper-container', {
        slidesPerView: 2,
        spaceBetween: -10,
        observer:true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.video-swiper-pagination'
        },
        loop: true,
        speed: 600,
        on: {
          click: function () {
            vm.changeRouter(vm.famousList[this.clickedSlide.dataset.index])
          }
        }
      })
    },
    // 新增点击量
    addClickNum(id) {
      this.$http.post(this.$baseURL.index.clickNum(), {
        id,
        tableType: 3
      }, 1).then((res) => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.content{
  position: relative;
  padding: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  background: transparent;
  z-index: 1;
  .video-swiper-pagination{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
    // width: 100%;
    align-items: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    /deep/.swiper-pagination-bullet{
      height: 6PX;
      width: 6PX;
      transition: all ease .3s;
      background: rgba(0 ,0 ,0, .23);
    }
    /deep/.swiper-pagination-bullet-active{
      width: 20PX;
      border-radius: 3PX;
      background: @themeColor;
    }
  }
  .swiper-main{
    position: relative;
    width: 100%;
    overflow: hidden;
    background: rgba(255,255,255,.9);
    backdrop-filter: blur(10px);
    box-shadow: 1px 0 3px 0 rgba(0 ,0 ,0, .05);
    border-radius: 10px;
    padding: 3px 0 25px;
  }
  .video-title{
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    padding-bottom: 20px;
    .title-more{
      font-size: 14px;
      font-weight: normal;
      color: #fff;
      display: flex;
      align-items: center;
    }
  }
  .daka-item{
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: scale(.88);
    font-size: 12px;
    color: #3E3F53;
    .img-box{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 35px;
      height: 100px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .play-icon{
        position: absolute;
        color: #fff;
      }
    }
    .daka-desc{
      width: 100%;
      margin-top: 5px;
    }
  }
}
</style>